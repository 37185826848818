export default function () {

    return {
        rating: 0,
        hoverRating: 0,
        ratings: [{'amount': 1, 'label':'Terrible'}, {'amount': 2, 'label':'Bad'}, {'amount': 3, 'label':'Okay'}, {'amount': 4, 'label':'Good'}, {'amount': 5, 'label':'Great'}],
        
        rate(amount) {
            this.rating = amount;
            this.reviewData.rating =  this.rating;
        },

        reviewData: {
            rating: '',
            text: '',
            parent_id: '',
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
        },

        reviewDataError: {
            rating: '',
            text: '',
            parent_id: '',
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
            physician_id: '',
        },

        formMessage: '',
        formLoading: false,
        buttonText: 'Leave a Review',

        submitReview(physicianId, reviewerEmail) {
            this.formMessage = '';
            this.formLoading = true;
            this.buttonText = 'Leaving Review...';
            fetch('/data/reviews/'+ physicianId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                },
                body: JSON.stringify(this.reviewData),
            })
            .then(response => response.json())
            .then((data) => {

                if(data.status === 'error' ) {
                    this.reviewDataError.rating = data.data.rating
                    this.reviewDataError.text = data.data.text
                    this.reviewDataError.first_name = data.data.first_name
                    this.reviewDataError.last_name = data.data.last_name
                    this.reviewDataError.email = data.data.email
                    this.reviewDataError.password = data.data.password
                    this.reviewDataError.password_confirmation = data.data.password_confirmation
                    this.reviewDataError.physician_id = data.data.physician_id
                }
                if(data.status === 'ok' ) {

                    this.formMessage = 'You have successfully left a review.'

                    window.dataLayer.push({
                        'event': 'review_left',
                        'user_email': reviewerEmail
                    });
                    
                    this.step = 2
                }
            })
            .finally(() => {
                this.formLoading = false;
                this.buttonText = 'Leave a Review';
            });
        },
    };
}