import jQuery from 'jquery';
window.$ = jQuery;

require('./bootstrap.js');

import Alpine from 'alpinejs'

import intersect from '@alpinejs/intersect'
 
window.Alpine = Alpine

Alpine.plugin(intersect)

import { MeiliSearch } from 'meilisearch' 

window.MeiliSearch = MeiliSearch

import search from './components/search.js';
import subscribe from './components/subscribe-landing';
import review from './components/leave-review-landing';

window.components = {
    search,
    subscribe,
    review,
};

Alpine.start()