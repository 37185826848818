export default function () {

    return {
        subscriberData: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
        },

        subscriberFormMessage: '',
        subscriberFormLoading: false,
        subscriberButtonText: 'Start Free Doctor Tracking',
        subscriberErrorPhysician: '',
        subscriberErrorEmail: '',

        subscribe(e) {

            this.subscriberErrorEmail = ''
            this.subscriberErrorPhysician = ''

            var password = Math.random().toString(36).slice(-8);
            var physicianId = e.target.dataset.physician
            var npi = e.target.dataset.npi

            this.subscriberData.first_name = 'New'
            this.subscriberData.last_name = 'Patient'
            this.subscriberData.password = password
            this.subscriberData.password_confirmation = password

            if(physicianId == 0) {
                this.subscriberErrorPhysician = 'Please select a doctor'
            }
            else {
                this.subscriberFormMessage = '';
                this.subscriberFormLoading = true;
                this.subscriberButtonText = 'Starting Track...';

                fetch('/data/subscribe/'+ physicianId, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                    },
                    body: JSON.stringify(this.subscriberData),
                })
                .then(response => response.json())
                .then((data) => {
                    if(data.status === 'error' ) {
                        this.subscriberErrorEmail = data.data.email
                    }
                    if(data.status === 'ok' ) {
                        window.dataLayer.push({
                            'event': 'review_requested',
                            'user_email': this.subscriberData.email
                        });
                        window.location.replace('/thank-you/' + npi);
                    }
                })
                .finally(() => {
                    this.subscriberFormLoading = false;
                    this.subscriberButtonText = 'Start Free Doctor Tracking';
                });
            }

        },

        async sendRequest(id) {
            return new Promise(resolve => {
              setTimeout(() => {
                fetch('/patients/subscriptions/data/' + id, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                    },
                })
                .then(response => response.json())
                .then((data) => {
                    if(data.status = 'ok') {}
                })
                resolve();
              }, 1000);
            });
        },

        async subscribeMultiple(e) {

            var physicianIds = e.target.dataset.physician

            const physicianIdsArray = physicianIds.split(',')

            physicianIdsArray.splice(-1)

            for (const id of physicianIdsArray) {
                await this.sendRequest(id)
            }

            window.dataLayer.push({
                'event': 'send_doctors_list'
            });

            setTimeout(() => {
                window.location.replace('/patients-pricing');
            }, 1000);

        },
    };
}